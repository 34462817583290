.pagination {
  float: right;
  li {
    margin: 0 2px 0 2px;
    a, span {
      border: solid 1px #9aafe5;
      font-weight: bold;
      color: #0e509e;
      padding: 1px 6px;
      text-decoration: none;

      &:hover {
        border: solid 1px #0e509e;
      }
    }
  }

  li.active {
    a, span {
      background: #2e6ab1;
      color: #FFFFFF;
      font-weight: bold;
    }
  }
}