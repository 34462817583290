.table {
  color: #555;
  font-size: 1.125em;

  td, th, caption {
    padding: 4px 10px 4px 5px;
    border: 1px white solid;
  }

  th {
    font-weight: bold;
    font-size: 0.9em;
    border: 1px white solid;
    padding: 0.3em 0.6em;
    vertical-align: top;
  }

  thead {
    background: linear-gradient(to bottom, #5d7d9e 0%, #20577f 100%);
    color: white;
  }

  tbody {

    tr:not(.filters) {
      background: #F8F8F8;
    }

    tr:nth-of-type(2n+1):not(.filters) {
      background: #E5F1F4;
    }

    //&:not(.search_row):hover {
    //    background: #ECFBD4
    //}
    tr:nth-of-type(2n+1):not(.filters):hover {
      background: #ECFBD4;
    }

    .icon-td {
      width: 10px;
    }
  }
}

.material-table {
  border-collapse: collapse;
  margin-bottom: 1.4em;
  width: 100%;
  border-spacing: 0;

  thead {
    tr {
      border-bottom: 1px solid #d0d0d0;

      th {
        font-weight: bold;
        float: none !important;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #d0d0d0;
      td:first-child {
        font-weight: bold;
      }
    }
  }

  th, td {
    color: rgba(0, 0, 0, 0.87);
    background: none;
    padding: 1em 0.33em;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
  }
}

//Custom tables
.surveydetails-table {
  tbody > tr > th {
    width: 160px;
    text-align: right;
  }

  tbody > tr > td {
    font-size: 0.9em;
  }
}

.overflow_dots {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-icon-group {
  width: 1%;
  white-space: nowrap;

  a {
    padding: 0 5px 0 5px;
  }
}

.text-underlined {
  text-decoration-line: underline;
}

.table-nostyle {
  tbody, thead {
    tr {
      background: inherit !important;
    }
  }
}