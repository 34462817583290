// Fonts
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~jquery-ui/themes/base/all.css';
@import "~timepicker/jquery.timepicker.min.css";
@import '~datatables.net-dt/css/jquery.dataTables.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}


// Custom css
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  background-color: #ffff;
  color: #1e0028;
  font-family: "Inter", sans-serif;
  font-size: 13px;
}

#app {
  min-height: 100vh;
}

main {
  flex: 1 0 0;
  display: flex;
  width: 100%;
}

.page-footer {
  flex-shrink: 0;
}

label {
  font-weight: bold;
  text-align: right;
}

.required {
  color: $red;
}

.pi-blue-text {
  color: $pi-blue;
}

.pi-blue-background {
  background-color: $pi-blue;
}

.white-text {
  color: #ffff;
}

.white-background {
  background-color: #ffff;
}

.full-width {
  width: 100%;
}

.breadcrumb-custom {
  background-color: #ffff;
}

.breadcrumb-custom .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #000000;
}

.offset-10 {
  margin-left: 10px;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.font-4rem {
  font-size: 4rem;
}

.a-nostyle {
  text-decoration: none;
  color: inherit;
}

.hidden {
  display: none;
}

.checkbox_label_left {
  float: left;
  margin-right: 10px;
  position: relative;
  text-align: right;
  width: 200px;
}

.inline-form-icon {
  display: inline-block;
  width: 85%;
}

.align-right {
  text-align: right;
}

.hint {
  margin: 0;
  padding: 0;
  padding-left: 0px;
  color: #999;
}

.btn-standard {
  border: 1px solid #E0E0E0;
  border-bottom-width: 1px;
  background: #ee4400;
  font-weight: normal;
  color: #fff;
  padding: .5em 1em;
  font-size: 12px;

  &:hover {
    background: #20577f !important;
    color: #fff;
  }
}

.custom-nav-tabs {
  a:not(.dropdown-item) {
    background: #ee4400;
    font-weight: normal;
    color: #fff;
  }

  a.active {
    background: #20577f !important;
    font-weight: normal !important;
    color: #fff !important;
  }

  .nav-item {
    flex: initial;
  }
}

.search_box {
  padding: 10px;
  margin: 10px 0;
}

.filter, .sFilter {
  width: 100%;
  height: 20px;
}

//donut layout
.donut {
  border-radius: 75px;
  height: 150px;
  width: 150px;
  margin: 0 auto;

  .inner {
    width: 120px;
    height: 120px;
    position: relative;
    top: 15px;
    left: 15px;
    background: #FFF;
    -webkit-border-radius: 65px;
    -moz-border-radius: 65px;
    border-radius: 65px;
    text-align: center;
    -webkit-animation-name: donut-anim;
    -webkit-animation-duration: 0.4s;
    animation-name: donut-anim;
    animation-duration: 0.4s;

    .label {
      transform: translateY(85%);
    }

    i, span {
      display: block;
    }

    i {
      font-size: 20px;
      font-style: initial;
      font-weight: bold;
    }
  }
}

.spacing-bottom {
  margin-bottom: 10px;
}

.teal {
  background: #009688;
  border-color: #009688;
}

.cyan {
  background: #00bcd4;
  border-color: #00bcd4;
}

.amber {
  background: #ffc107;
  border-color: #ffc107;
}

.blue-grey {
  background: #607d8b;
  border-color: #607d8b;
}

.spacing-bottom-40 {
  margin-bottom: 40px;
}

.route-card {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  margin-top: 5px;

  .route-card-content {
    padding: 10px;
  }

  .route-card-title {
    font-size: 15px;
  }

  .route-group-action-button {
    position: absolute;
    right: 3%;
    top: 7%;
    .fa-trash {
      color: grey;
    }
  }
}

.inline {
  display: inline-block;
}

.inline-badge {
  background: gray;
  border-radius: 70px;
  color: white;
  font-family: Helvetica;
  padding: 9px;
  position: relative;
  left: -30px;
  bottom: -40px;
}

.planned {
  color: blue;
}
.sent {
  color: green;
}

.fa_hover:hover {
  color: white;
}

#topButton {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: lightslategrey; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

#topButton:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}

.ui-dialog-titlebar-close:after {
  content: 'X' !important;
  position: absolute;
  top: -2px;
  right: 3px;
}

@import 'layout/base';
@import 'layout/table.scss';
@import 'layout/form.scss';
@import 'layout/pagination.scss';

.badge-dropped {
  background-color: #f1416c;
}

.badge-termed {
  background-color: #7239ea;
}


.tools {
  color: rgba(0,0,0,0.54);

  a:first-child {
    margin-left: 0;
  }

  a {
    font-size: 1em;
    margin-right: 0.92em;
    color: white;
    text-decoration: none;
  }
}

.custom-card {
  padding: 30px 40px;
  //margin-top: 30px;
  //margin-bottom: 30px;
  border: none !important;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);

  .form-control-label {
    margin-bottom: 0;
  }

  label {
    text-align: left;
  }

  input,
  textarea,
  button {
    padding: 8px 15px;
    border-radius: 5px !important;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 18px !important;
    font-weight: 300;
  }

  input:disabled {
    background: #d8d8d8;
    color: black;
  }

  input:focus,
  textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #00bcd4;
    outline-width: 0;
    font-weight: 400;
  }
  .btn-block {
    text-transform: uppercase;
    font-size: 15px !important;
    font-weight: 400;
    height: 43px;
    cursor: pointer;
  }
  .btn-block:hover {
    color: #fff !important;
  }
  button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
  }

}