//Header
.nav-bar-responsive {
    display: none;
}

.custom-nav {
    width: 100%;
    position: relative;

    //default color layout
    .custom-nav-content {
        width: 100%;

        ul {
            list-style: none;
            width: 100%;
            margin: 0;
            text-align: right;

            li {
                display: inline-block;

                a {
                    color: #21567E;
                    background-color: transparent;
                    font-size: 12px;
                    font-weight: bold;
                    text-decoration: none;
                    padding: 25px 30px;
                    display: inline-block;
                    border-right: 1px solid #efefef;
                    &:hover {
                        background-color: $red;
                        color: #ffff;
                    }
                }
            }
        }
        .active {
            a {
                color: #ffff;
                background-color: $red;
            }
        }
    }

    .nav-redline {
        width: 100%;
        background-color: $pi-red;
        height: 2px;
    }
    .nav-blueline {
        background-color: $pi-blue;
        width: 100%;
        height: 28px;
    }
    .fixed-height {
        height: 100px;
    }

}

@media screen and (max-width: $mobile) {
    .nav-bar-responsive {
        float: right;
        display: block;
        top: 20px;
        position: absolute;
        right: 20px;
        color: white;
        font-size: 24px;
    }
    .custom-nav {
        min-height: 50px;
    
        .custom-nav-content {
            width: 100%;
            //height: 0px;
            height: 100%;

            ul {
                padding: 0;
                margin: 0;
                background-color: white;

                li {
                    //display: block;
                    width: 100%;
                    text-align: left;
                    border-bottom: 1px solid black;

                    a {
                        width: 100%;
                    }
                }
                li:not(.active) {
                    display: none;
                }
            }
        }
    }
}

.action-card {
    border: 0;
    position: static;
    width: auto;

    .card-header {
        padding: 3px 8px;
        background: #B7D6E7;
        border-left: 5px solid #6FACCF;
        font-size: 12px;
        font-weight: bold;
        color: #298dcd;
    }
    .card-body {
        font-size: 0.9em;
        padding: 5px 8px;
        background: #EFFDFF;
        border: 0;

        ul {
            padding: 0;
            list-style: none;

            li {
                padding: 2px 0 2px 0px;

                a {
                    font: bold 12px Arial;
                    line-height: normal;
                    color: #0066A4;
                    display: block;
                    padding: 2px 0 2px 8px;
                    line-height: 15px;
                    text-decoration: none;

                    &:hover {
                        background-color: $red;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .action-card {
        margin-top: 0;
    }
}

.floating-top-right {
    top: 111px !important;
    left: 0px !important;
    margin-left: auto !important;
    right: 11px !important;
}

.fa-pencil-alt {
    color: #FA9A1B;
}

.fa-list {
    color: #009688;
}

.fa-search {
    color: black;
}

.fa-times {
    color: $red;
}

.fa-archive {
    color: $red;
}

.gray_box {
    background-color: $gray;
}

.fa-copy {
    color: #009688;
}

.fa-check {
    color: #009688;
}